/***    General     ***/
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "poppins",sans-serif
}

body {
    margin: 0 auto;
    font-family: "poppins",sans-serif
}

.m-5-auto {
    margin: 5rem auto
}

.primary-button {
    border: 2px solid #222;
    color: #fff;
    font-size: 1.2rem;
    transition: all .5s;
    cursor: pointer;
    text-transform: capitalize
}

.refresh {
    width: 30px;
}
.refresh-button {
    border-radius: 6px;
    background: white;
    border: 1px solid #D7D7D7;
    padding: 4px 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
}


/***    Landing Page     ***/
.main-title,
.main-para {
    color: #f1f1f1
}

.main-title {
    padding-top: 10rem;
    font-size: 3rem;
    text-transform: uppercase
}

.main-para {
    font-size: 2.5rem;
    text-Transform: capitalize
}

#reg_btn span {
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

#reg_btn span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

#reg_btn:hover span {
  padding-right: 25px;
}

#reg_btn:hover span:after {
  opacity: 1;
  right: 0;
}

/***    Login Page     ***/
h2 {
    font-weight: 300
}

form {
    display: inline-block;
    background: #f3f3f3;
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 2rem;
    margin: 2rem 0 1rem 0
}

form label {
    float: left;
    font-size: .9rem;
    margin: 0;
    padding: 0
}

.right-label {
    float: right;
    cursor: pointer
}

input {
    width: 15rem;
    padding: .3rem;
    border-radius: 5px;
    outline: none;
    border: none
}

#sub_btn {
    display: block;
    width: 100%;
    padding: .3rem;
    border: none;
    background: #222;
    color: #fff;
    border-radius: 3px;
}

#sub_btn:hover {
    background: #333;
    transition: all .5s
}

footer p {
    margin: 0;
    font-size: .9rem
}

/***    Register Page     ***/
#checkbox {
    width: 1rem
}

form span {
    font-size: .8rem
}

#reset_pass_lbl {
    float: left
}

/***    Home Page     ***/
.home-page-title {
    color: #222
}

.d-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-header {
    width: 100%;
    height: 80px;
    background: #fff;
    position: fixed;
    z-index: 30;
    border-bottom: 1px solid #e0e0e1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 48px;
}

.home-header-logo {
    width: 180px;
    height: 100px;
}

.home-header a {
    color: black;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 24px;
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.service-img {
    width: 20px;
    height: 20px;
    margin-left: 20px;
}


.p-16 {
    padding-top: 160px;
}

.rdt_TableRow {
    padding: 14px 0px;
}

.mt-4 {
    margin-top: 1.5rem;
}

.service-status-0 {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #ED4337;
}

.service-status-1 {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #00C851;
}


.service-status--1{
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #FFBB33;
}


.ml-2 {
   margin-left: 1rem;
}

.mr-2 {
    margin-right: 1rem;
}

.devops-image {
    width: 500px;
    object-fit: cover;
}
